import React, { useState, useMemo, useEffect, createContext } from 'react';
import csvFile from '../assets/results/results.csv';
import Papa from 'papaparse';

export const CsvContext = createContext();

export const CsvProvider = ({ children }) => {
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        const fetchParseData = async () => {
            Papa.parse(
                csvFile,
                {
                    delimiter: ';',
                    header: true,
                    skipEmptyLines: true,
                    download: true,
                    complete: ((results) => {
                        setCsvData(results.data);
                    }),
                },
            );
        };

        fetchParseData();
    }, []);

    const value = useMemo(
        () => ({
            csvData,
        }), [csvData],
    );

    return <CsvContext.Provider value={ value }>{ children }</CsvContext.Provider>;
};
