import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const Layout = lazy(() => import('./layouts/Layout'));
const Home = lazy(() => import('./pages/Home'));
const Price = lazy(() => import('./pages/Price'));
const Calendar = lazy(() => import('./pages/Calendar'));
const Repetition = lazy(() => import('./pages/Repetition'));
const Result = lazy(() => import('./pages/Result'));
const Form = lazy(() => import('./pages/Form'));
const Rules = lazy(() => import('./pages/Rules'));
const Contact = lazy(() => import('./pages/Contact'));

function App() {
    return (
        <>
            <Router>
                <Suspense fallback={ <div>Loading...</div> }>
                    <Routes>
                        <Route path="/" element={ <Layout /> }>
                            <Route index element={ <Home /> } />
                            <Route path="price" element={ <Price /> } />
                            <Route path="calendar" element={ <Calendar /> } />
                            <Route path="repetitions" element={ <Repetition /> } />
                            <Route path="results" element={ <Result /> } />
                            <Route path="forms" element={ <Form /> } />
                            <Route path="rules" element={ <Rules /> } />
                            <Route path="contact" element={ <Contact /> } />
                        </Route>
                    </Routes>
                </Suspense>
            </Router>
        </>
    );
}

export default App;
